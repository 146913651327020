@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Inter var", sans-serif;
}

h1 {
    @apply text-2xl;
}
h2 {
    @apply text-xl;
}
h3 {
    @apply text-lg;
}
a {
    @apply text-blue-600;
}

/* Datepicker */
.has-error .rs-picker-toggle {
    @apply !border-red-500;
    @apply !ring-red-500;
    @apply !shadow-none;
}

.has-error .rs-picker-toggle-textbox {
    @apply placeholder-red-500;
    @apply !border-red-500;
    @apply !shadow-none;
}

.has-error .rs-picker-toggle-placeholder {
    @apply text-red-500;
}

.rs-picker-menu {
    z-index: 99999;
}

.react-datepicker__input-container input {
    @apply block;
    @apply w-full;
    @apply text-sm !important;
    @apply bg-white;
    @apply rounded border border-gray-300;
    @apply shadow-sm;
}

.react-datepicker__tab-loop {
    @apply absolute;
}

.react-datepicker-popper {
    @apply absolute left-0 top-1;
    @apply z-10;
    @apply w-72 px-3 py-2;
    @apply text-sm;
    @apply transform-none;
    @apply bg-white shadow;
}

.react-datepicker__month-container {
    @apply flex flex-col;
}

.react-datepicker__month {
    @apply flex flex-col;
}

.react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
    @apply flex justify-around;
}

.react-datepicker__day-names {
    @apply flex justify-around;
    @apply text-center text-xs font-medium text-gray-800;
}

.react-datepicker__day-name {
    @apply flex items-center justify-center;
    @apply h-8 w-8 py-1;
    @apply rounded-full;
}

.react-datepicker__navigation {
    @apply absolute top-2;
}

.react-datepicker__navigation--previous {
    @apply flex items-center justify-center;
    @apply h-8 w-8;
    @apply transition;
    @apply rounded;
    @apply right-12;
    @apply hover:bg-gray-200;
}

.react-datepicker__navigation--next {
    @apply right-4 hover:bg-gray-200;
    @apply flex items-center justify-center;
    @apply h-8 w-8;
    @apply transition;
    @apply rounded;
}

.react-datepicker__day {
    @apply flex items-center justify-center;
    @apply mb-1 h-8 w-8 py-1;
    @apply text-sm leading-loose text-gray-700;
    @apply transition;
    @apply rounded;
}

.react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
    @apply text-gray-400;
}

.react-datepicker__day--in-range {
    @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
    @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
    @apply border-2 border-blue-500 bg-white;
}

.react-datepicker__day--selected {
    @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
    @apply bg-blue-500 hover:bg-white;
    @apply text-white hover:text-gray-700;
}

.react-datepicker__close-icon {
    @apply absolute;
    @apply right-2;
    @apply top-3;
}

.rs-picker-default div.rs-picker-toggle {
    z-index: initial;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    @apply text-gray-900;
}

.striped,
.striped .b-cal-event {
    background-image: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 0.0625rem,
        hsla(0, 0%, 100%, 1) 0.0625rem,
        hsla(0, 0%, 100%, 1) 0.5625rem
    );
}

.highlight {
    background-color: rgba(255, 255, 255, 0.7);
}

.rs-calendar-table-cell-week-number {
    @apply cursor-pointer;
}

@media (max-width: 1580px) {
    .b-calendar .b-calendar-view-desc {
        @apply text-sm;
    }
}

.leaflet-container {
    height: 100%;
}

.leaflet-div-icon {
    @apply bg-transparent;
    @apply flex items-end justify-center;
    @apply border-0;
}

.react-select-container > * > * > * > input {
    box-shadow: none !important;
}

.leaflet-control-attribution {
    display: none;
}

.react-select-container > div[class*="-control"] {
    min-height: 36px;
}

.b-grid-cell a[href] {
    @apply text-blue-500;
}

div.ql-editor {
    cursor: text;
    resize: vertical;
    min-height: 60px;
    height: 120px;
}

.custom-input-field {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.custom-input-field::-webkit-inner-spin-button,
.custom-input-field::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.schedule-overview > div:nth-child(odd) {
    background: rgba(247, 247, 249, 0.8);
}

.react-tooltip {
    @apply px-2 py-1;
}

.rs-picker-toggle-textbox {
    font-size: 14px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-top: 8px;
    padding-bottom: 8px;
}

.rs-picker-menu {
    margin-top: 0.5rem;
    z-index: 99999 !important;
}

.rs-picker-toolbar.rs-stack {
    border: 0;
}
