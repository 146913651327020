.element-marker > div {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    color: white;
    display: flex;
    justify-content: center;
}

.element-marker svg {
    margin: 9px 10px;
    font-size: 18px;
}

.element-marker p {
    margin: 3px 12px;
    font-size: 20px;
    display: block;
    position: relative;
    float: left;
}
