.b-resource-info {
    @apply w-full gap-x-4;
}
.b-resource-info dd {
    @apply text-gray-900;
}

.b-resource-initials {
    @apply text-sm font-bold;
}

.b-resource-info .b-resource-avatar {
    margin-inline-end: 0;
}

dl[role="presentation"] {
    @apply flex-grow overflow-hidden;
}

.b-scheduler-body-wrap .b-grid-splitter .b-grid-splitter-buttons {
    display: none !important;
}
