.b-grid-header-container {
    @apply border-b border-t border-solid border-gray-300;
}

.b-grid-header {
    @apply bg-gray-100;
}

.b-grid-header-text {
    @apply p-1 text-base;
}

.datatable .b-grid-header {
    justify-content: inherit;
    @apply border-t border-solid border-gray-300;
}

.b-filter-bar-field {
    @apply m-0;
}

.b-filter-bar-field.b-textfield .b-field-inner,
.b-filter-bar-field.b-textareafield .b-field-inner {
    @apply mb-1 h-7;
}

.b-filter-bar-field.b-textfield.b-combo .b-field-inner {
    @apply h-7 min-h-[1.75rem];
}

.b-grid-header {
    @apply justify-start;
    @apply normal-case;
}

.b-button {
    @apply normal-case;
}

.b-collapsetool:not(.b-collapsed):before {
    @apply rotate-180;
}

.b-collapsetool.b-collapsed:before {
    @apply rotate-0;
}

#to-be-planned .b-panel-ui-calendar-banner.b-panel-header.b-dock-top {
    @apply h-[65px];
}

#to-be-planned .b-panel-ui-calendar-banner.b-panel-header.b-dock-left .b-header-title {
    @apply mt-2 rotate-0;
}
