.b-textfield.b-combo .b-field-inner {
    @apply h-auto max-h-[38px] min-h-[36px];
}

.b-textfield.b-combo.b-multiselect .b-field-inner {
    @apply max-h-[120px];
}

.b-combo.b-uses-chipview .b-chipview {
    @apply m-2;
}

.b-chip {
    @apply p-1 text-sm leading-[.825rem];
}

.b-chip .b-icon {
    @apply h-4 w-4;
}

.b-combo.b-uses-chipview .b-chipview {
    @apply m-1;
}

.b-readonly .b-field-inner {
    @apply bg-gray-100 transition-colors;
}

.b-numberfield:not(.b-disabled):hover .b-field-inner,
.b-textareafield:not(.b-disabled):hover .b-field-inner,
.b-textfield:not(.b-disabled):hover .b-field-inner {
    @apply border-gray-300;
}

.b-textfield.b-combo:not(.b-disabled):hover .b-field-inner {
    @apply bg-gray-200 transition-colors;
}

.b-numberfield.b-contains-focus .b-field-inner,
.b-textareafield.b-contains-focus .b-field-inner,
.b-textfield.b-contains-focus .b-field-inner {
    @apply border-blue-600;
}
