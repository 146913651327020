.b-checkbox .b-checkbox-label {
    @apply text-gray-900;
}

.b-checkbox.b-disabled .b-checkbox-label {
    @apply text-gray-100;
}

.b-checkbox.b-disabled .b-checkbox-label::before {
    @apply border-gray-300;
}

.b-checkbox.b-disabled input[type="checkbox"]:checked + .b-checkbox-label:before {
    @apply border-gray-300;
    @apply bg-gray-200;
}

.b-container .b-checkbox .b-checkbox-label:before {
    @apply mt-3;
    @apply text-gray-200;
}

.b-container .b-checkbox .b-checkbox-label {
    @apply text-gray-900;
}

.b-checkbox .b-checkbox-label:before {
    @apply border-gray-300;
    @apply text-sm;
    @apply text-gray-900;
}

.b-checkbox input[type="checkbox"]:checked + .b-checkbox-label:before {
    @apply text-white !important;
    @apply bg-blue-500 !important;
    @apply border-blue-500 !important;
}

.b-checkbox.b-blue.b-disabled .b-checkbox-label::before {
    @apply border-blue-300;
}

.b-checkbox.b-blue.b-disabled input[type="checkbox"]:checked + .b-checkbox-label:before {
    @apply bg-blue-300;
    @apply border-blue-300;
}
