.b-popup-header {
    @apply px-2 py-0 !important;
}

.b-overflowpopup-body-wrap {
    @apply bg-transparent !important;
}

.b-overflowpopup-content {
    @apply m-2 !important;
}
