.b-grid-row {
    @apply border-b border-gray-200 !important;
}

.b-grid-row.b-selected,
.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover,
.b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-timeaxissubgrid)
    .b-grid-row:not(.b-group-row).b-hover.b-selected {
    @apply bg-white;
}
