.b-popup-content.b-panel-content > div,
.b-panel.b-floating > .b-panel-body-wrap > .b-panel-content {
    @apply p-0;
}

.b-panel.b-floating > .b-panel-body-wrap > .b-panel-content.b-tooltip-content {
    @apply bg-transparent p-0;
}

.b-popup.b-panel.b-floating.b-tooltip {
    @apply rounded-lg
        border
        border-gray-200
        bg-white
        text-sm
        font-medium
        text-gray-900
        shadow-sm;
}

.b-popup.b-panel.b-floating.b-tooltip.small > .b-panel-body-wrap > .b-panel-content.b-tooltip-content {
    @apply m-0 px-2 py-1 text-white;
}

.b-popup.b-panel.b-floating.b-tooltip.small {
    @apply rounded
        border-0
        bg-gray-800
        text-white
        opacity-90;
    --panel-background-color: transparent;
}

.b-popup-content.b-menu-content.b-panel-content > div.b-menuitem {
    @apply p-2;
}

.b-popup-content.b-menu-content.b-panel-content > div.b-menuitem:hover {
    @apply bg-gray-100 !important;
}
