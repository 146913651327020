button.b-button,
.b-button {
    @apply relative;
    @apply inline-flex items-center;
    @apply px-2.5 py-1.5;
    @apply rounded border border-transparent;
    @apply text-sm font-bold text-white;
    @apply bg-blue-500 shadow-sm hover:bg-blue-700;
    @apply disabled:bg-blue-500;
    @apply disabled:opacity-50;
    @apply disabled:cursor-not-allowed !important;
    @apply focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
    @apply min-h-0;
    height: 2.3rem;
}

.b-button i {
    @apply text-lg;
}

.b-button:not(.b-tab) {
    @apply text-sm font-bold text-white;
    @apply rounded border border-transparent;
}

.b-button:disabled > i,
.b-button:disabled > label {
    @apply cursor-not-allowed !important;
}

.b-button:not(.b-tab),
.b-button.b-blue:not(.b-tab) {
    @apply bg-blue-500;
    @apply border-blue-500;
    @apply text-white;
}

.b-using-keyboard .b-button:not(.b-tab):focus,
.b-button:not(.b-tab).b-using-keyboard:focus,
.b-using-keyboard .b-button.b-blue:not(.b-tab):focus,
.b-button.b-blue:not(.b-tab).b-using-keyboard:focus {
    @apply bg-blue-400;
    @apply text-white;
}

.b-button:not(.b-tab).b-pressed,
.b-button.b-blue:not(.b-tab).b-pressed {
    @apply bg-blue-800;
    @apply text-white;
}

.b-button:not(.b-tab):hover:not(.b-disabled),
.b-button.b-blue:not(.b-tab):hover:not(.b-disabled) {
    @apply bg-blue-600;
    @apply text-white;
}

.b-button:not(.b-tab):hover:not(.b-disabled):not(.b-pressed),
.b-button.b-blue:not(.b-tab):hover:not(.b-disabled):not(.b-pressed) {
    @apply bg-blue-600;
    @apply text-white;
}

.b-button:not(.b-tab).b-pressed:hover,
.b-button.b-blue:not(.b-tab).b-pressed:hover {
    @apply bg-blue-600;
    @apply text-white;
}

.b-button:not(.b-tab):active:not(.b-disabled),
.b-button:not(.b-tab):active:focus:not(.b-disabled),
.b-button.b-blue:not(.b-tab):active:not(.b-disabled),
.b-button.b-blue:not(.b-tab):active:focus:not(.b-disabled) {
    @apply bg-blue-700;
    @apply text-white;
}

.b-button:not(.b-tab).b-disabled,
.b-button.b-blue:not(.b-tab).b-disabled {
    @apply bg-gray-100;
    @apply border-blue-500;
    @apply text-gray-900;
}

.b-button.b-red:not(.b-tab) {
    @apply text-white;
    @apply bg-red-500;
    @apply border-red-500;
}

.b-using-keyboard .b-button.b-red:not(.b-tab):focus,
.b-button.b-red:not(.b-tab).b-using-keyboard:focus {
    @apply bg-red-400;
    @apply text-white;
}

.b-button.b-red:not(.b-tab).b-pressed {
    @apply bg-red-800;
    @apply text-white;
}

.b-button.b-red:not(.b-tab):hover:not(.b-disabled) {
    @apply bg-red-400;
    @apply text-white;
}

.b-button.b-red:not(.b-tab):hover:not(.b-disabled):not(.b-pressed) {
    @apply bg-red-400;
    @apply text-white;
}

.b-button.b-red:not(.b-tab).b-pressed:hover {
    @apply bg-red-400;
    @apply text-white;
}

.b-button.b-red:not(.b-tab):active:not(.b-disabled),
.b-button.b-red:not(.b-tab):active:focus:not(.b-disabled) {
    @apply bg-red-700;
    @apply text-white;
}

.b-button.b-red:not(.b-tab).b-disabled {
    @apply bg-gray-100;
    @apply border-red-500;
    @apply text-gray-900;
}

.b-button.b-white:not(.b-tab) {
    @apply font-normal text-gray-900;
    @apply bg-white;
    @apply border-gray-300;
}

.b-using-keyboard .b-button.b-white:not(.b-tab):focus,
.b-button.b-white:not(.b-tab).b-using-keyboard:focus {
    @apply bg-white;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-white:not(.b-tab).b-pressed {
    @apply bg-white;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-white:not(.b-tab):hover:not(.b-disabled) {
    @apply bg-gray-100;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-white:not(.b-tab):hover:not(.b-disabled):not(.b-pressed) {
    @apply bg-gray-200;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-white:not(.b-tab).b-pressed:hover {
    @apply bg-white;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-white:not(.b-tab):active:not(.b-disabled),
.b-button.b-white:not(.b-tab):active:focus:not(.b-disabled) {
    @apply bg-white;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-white:not(.b-tab).b-disabled {
    @apply bg-gray-100;
    @apply border-gray-300;
    @apply font-normal text-gray-900;
}

.b-button.b-gray:not(.b-tab) {
    @apply text-white;
    @apply bg-gray-400;
    @apply border-gray-400;
}

.b-using-keyboard .b-button.b-gray:not(.b-tab):focus,
.b-button.b-gray:not(.b-tab).b-using-keyboard:focus {
    @apply bg-gray-200;
    @apply text-white;
}

.b-button.b-gray:not(.b-tab).b-pressed {
    @apply bg-gray-600;
    @apply text-white;
}

.b-button.b-gray:not(.b-tab):hover:not(.b-disabled) {
    @apply bg-gray-300;
    @apply text-white;
}

.b-button.b-gray:not(.b-tab):hover:not(.b-disabled):not(.b-pressed) {
    @apply bg-gray-300;
    @apply text-white;
}

.b-button.b-gray:not(.b-tab).b-pressed:hover {
    @apply bg-gray-300;
    @apply text-white;
}

.b-button.b-gray:not(.b-tab):active:not(.b-disabled),
.b-button.b-gray:not(.b-tab):active:focus:not(.b-disabled) {
    @apply bg-gray-500;
    @apply text-white;
}

.b-button.b-gray:not(.b-tab).b-disabled {
    @apply bg-gray-100;
    @apply border-gray-400;
    @apply text-gray-900;
}

.b-button.b-raised {
    @apply text-white;
    @apply bg-blue-500;
}

.b-outer.b-using-keyboard .b-button.b-raised:focus,
.b-button.b-raised.b-outer.b-using-keyboard:focus {
    @apply bg-blue-400;
}

.b-outer.b-using-keyboard .b-button.b-raised:focus:hover:not(.b-disabled),
.b-button.b-raised.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised:hover:not(.b-disabled) {
    @apply bg-blue-500;
}

.b-button.b-raised.b-pressed,
.b-button.b-raised.b-pressed[data-group]:hover {
    @apply bg-blue-800;
}

.b-button.b-raised.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-pressed:focus:hover,
.b-button.b-raised.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-pressed:focus,
.b-button.b-raised.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-blue-500;
}

.b-button.b-raised.b-pressed:active {
    @apply bg-blue-700;
}

.b-button.b-raised.b-pressed:active:hover {
    @apply bg-blue-500;
}

.b-button.b-raised:active:not(.b-disabled) {
    @apply bg-blue-700;
    @apply border-blue-700;
}

.b-button.b-raised:active:hover:not(.b-disabled) {
    @apply bg-blue-500;
    @apply border-blue-500;
}

.b-button.b-raised:disabled:hover,
.b-button.b-raised:disabled {
    @apply bg-gray-100;
    @apply border-blue-500;
    @apply text-gray-900;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-blue:focus,
.b-button.b-raised.b-blue.b-outer.b-using-keyboard:focus {
    @apply bg-blue-400;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-blue:focus:hover:not(.b-disabled),
.b-button.b-raised.b-blue.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-blue:hover:not(.b-disabled) {
    @apply bg-blue-500;
}

.b-button.b-raised.b-blue.b-pressed,
.b-button.b-raised.b-blue.b-pressed[data-group]:hover {
    @apply bg-blue-800;
}

.b-button.b-raised.b-blue.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-blue.b-pressed:focus:hover,
.b-button.b-raised.b-blue.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-blue.b-pressed:focus,
.b-button.b-raised.b-blue.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-blue-500;
}

.b-button.b-raised.b-blue.b-pressed:active {
    @apply bg-blue-700;
}

.b-button.b-raised.b-blue.b-pressed:active:hover {
    @apply bg-blue-500;
}

.b-button.b-raised.b-blue:active:not(.b-disabled) {
    @apply bg-blue-700;
    @apply border-blue-700;
}

.b-button.b-raised.b-deep-orange {
    @apply text-white;
    @apply bg-orange-600;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-deep-orange:focus,
.b-button.b-raised.b-deep-orange.b-outer.b-using-keyboard:focus {
    @apply bg-orange-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-deep-orange:focus:hover:not(.b-disabled),
.b-button.b-raised.b-deep-orange.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-deep-orange:hover:not(.b-disabled) {
    @apply bg-orange-300;
}

.b-button.b-raised.b-deep-orange.b-pressed,
.b-button.b-raised.b-deep-orange.b-pressed[data-group]:hover {
    @apply bg-orange-400;
}

.b-button.b-raised.b-deep-orange.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-deep-orange.b-pressed:focus:hover,
.b-button.b-raised.b-deep-orange.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-deep-orange.b-pressed:focus,
.b-button.b-raised.b-deep-orange.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-orange-500;
}

.b-button.b-raised.b-deep-orange.b-pressed:active {
    @apply bg-orange-700;
}

.b-button.b-raised.b-deep-orange.b-pressed:active:hover {
    @apply bg-orange-500;
}

.b-button.b-raised.b-deep-orange:active:not(.b-disabled) {
    @apply bg-orange-700;
    @apply border-orange-700;
}

.b-button.b-raised.b-deep-orange:active:hover:not(.b-disabled) {
    @apply bg-orange-500;
    @apply border-orange-500;
}

.b-button.b-raised.b-deep-orange:disabled:hover,
.b-button.b-raised.b-deep-orange:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-orange-600;
}

.b-button.b-raised.b-orange {
    @apply text-white;
    @apply bg-orange-600;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-orange:focus,
.b-button.b-raised.b-orange.b-outer.b-using-keyboard:focus {
    @apply bg-orange-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-orange:focus:hover:not(.b-disabled),
.b-button.b-raised.b-orange.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-orange:hover:not(.b-disabled) {
    @apply bg-orange-300;
}

.b-button.b-raised.b-orange.b-pressed,
.b-button.b-raised.b-orange.b-pressed[data-group]:hover {
    @apply bg-orange-400;
}

.b-button.b-raised.b-orange.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-orange.b-pressed:focus:hover,
.b-button.b-raised.b-orange.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-orange.b-pressed:focus,
.b-button.b-raised.b-orange.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-orange-500;
}

.b-button.b-raised.b-orange.b-pressed:active {
    @apply bg-orange-700;
}

.b-button.b-raised.b-orange.b-pressed:active:hover {
    @apply bg-orange-500;
}

.b-button.b-raised.b-orange:active:not(.b-disabled) {
    @apply bg-orange-700;
    @apply border-orange-700;
}

.b-button.b-raised.b-orange:active:hover:not(.b-disabled) {
    @apply bg-orange-500;
    @apply border-orange-500;
}

.b-button.b-raised.b-orange:disabled:hover,
.b-button.b-raised.b-orange:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-orange-600;
}

.b-button.b-raised.b-amber {
    @apply text-white;
    @apply bg-amber-600;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-amber:focus,
.b-button.b-raised.b-amber.b-outer.b-using-keyboard:focus {
    @apply bg-amber-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-amber:focus:hover:not(.b-disabled),
.b-button.b-raised.b-amber.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-amber:hover:not(.b-disabled) {
    @apply bg-amber-300;
}

.b-button.b-raised.b-amber.b-pressed,
.b-button.b-raised.b-amber.b-pressed[data-group]:hover {
    @apply bg-amber-400;
}

.b-button.b-raised.b-amber.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-amber.b-pressed:focus:hover,
.b-button.b-raised.b-amber.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-amber.b-pressed:focus,
.b-button.b-raised.b-amber.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-amber-500;
}

.b-button.b-raised.b-amber.b-pressed:active {
    @apply bg-amber-700;
}

.b-button.b-raised.b-amber.b-pressed:active:hover {
    @apply bg-amber-500;
}

.b-button.b-raised.b-amber:active:not(.b-disabled) {
    @apply bg-amber-700;
    @apply border-amber-700;
}

.b-button.b-raised.b-amber:active:hover:not(.b-disabled) {
    @apply bg-amber-500;
    @apply border-amber-500;
}

.b-button.b-raised.b-amber:disabled:hover,
.b-button.b-raised.b-amber:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-amber-600;
}

.b-button.b-raised.b-indigo {
    @apply bg-indigo-600;
    @apply text-white;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-indigo:focus,
.b-button.b-raised.b-indigo.b-outer.b-using-keyboard:focus {
    @apply bg-indigo-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-indigo:focus:hover:not(.b-disabled),
.b-button.b-raised.b-indigo.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-indigo:hover:not(.b-disabled) {
    @apply bg-indigo-300;
}

.b-button.b-raised.b-indigo.b-pressed,
.b-button.b-raised.b-indigo.b-pressed[data-group]:hover {
    @apply bg-indigo-400;
}

.b-button.b-raised.b-indigo.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-indigo.b-pressed:focus:hover,
.b-button.b-raised.b-indigo.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-indigo.b-pressed:focus,
.b-button.b-raised.b-indigo.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-indigo-500;
}

.b-button.b-raised.b-indigo.b-pressed:active {
    @apply bg-indigo-700;
}

.b-button.b-raised.b-indigo.b-pressed:active:hover {
    @apply bg-indigo-500;
}

.b-button.b-raised.b-indigo:active:not(.b-disabled) {
    @apply bg-indigo-700;
    @apply border-indigo-700;
}

.b-button.b-raised.b-indigo:active:hover:not(.b-disabled) {
    @apply bg-indigo-500;
    @apply border-indigo-500;
}

.b-button.b-raised.b-indigo:disabled:hover,
.b-button.b-raised.b-indigo:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-indigo-600;
}

.b-button.b-raised.b-lime {
    @apply bg-lime-600;
    @apply text-white;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-lime:focus,
.b-button.b-raised.b-lime.b-outer.b-using-keyboard:focus {
    @apply bg-lime-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-lime:focus:hover:not(.b-disabled),
.b-button.b-raised.b-lime.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-lime:hover:not(.b-disabled) {
    @apply bg-lime-300;
}

.b-button.b-raised.b-lime.b-pressed,
.b-button.b-raised.b-lime.b-pressed[data-group]:hover {
    @apply bg-lime-400;
}

.b-button.b-raised.b-lime.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-lime.b-pressed:focus:hover,
.b-button.b-raised.b-lime.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-lime.b-pressed:focus,
.b-button.b-raised.b-lime.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-lime-500;
}

.b-button.b-raised.b-lime.b-pressed:active {
    @apply bg-lime-700;
}

.b-button.b-raised.b-lime.b-pressed:active:hover {
    @apply bg-lime-500;
}

.b-button.b-raised.b-lime:active:not(.b-disabled) {
    @apply bg-lime-700;
    @apply border-lime-700;
}

.b-button.b-raised.b-lime:active:hover:not(.b-disabled) {
    @apply bg-lime-500;
    @apply border-lime-500;
}

.b-button.b-raised.b-lime:disabled:hover,
.b-button.b-raised.b-lime:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-lime-600;
}

.b-button.b-raised.b-green {
    @apply bg-green-600;
    @apply text-white;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-green:focus,
.b-button.b-raised.b-green.b-outer.b-using-keyboard:focus {
    @apply bg-green-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-green:focus:hover:not(.b-disabled),
.b-button.b-raised.b-green.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-green:hover:not(.b-disabled) {
    @apply bg-green-300;
}

.b-button.b-raised.b-green.b-pressed,
.b-button.b-raised.b-green.b-pressed[data-group]:hover {
    @apply bg-green-400;
}

.b-button.b-raised.b-green.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-green.b-pressed:focus:hover,
.b-button.b-raised.b-green.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-green.b-pressed:focus,
.b-button.b-raised.b-green.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-green-500;
}

.b-button.b-raised.b-green.b-pressed:active {
    @apply bg-green-700;
}

.b-button.b-raised.b-green.b-pressed:active:hover {
    @apply bg-green-500;
}

.b-button.b-raised.b-green:active:not(.b-disabled) {
    @apply bg-green-700;
    @apply border-green-700;
}

.b-button.b-raised.b-green:active:hover:not(.b-disabled) {
    @apply bg-green-500;
    @apply border-green-500;
}

.b-button.b-raised.b-green:disabled:hover,
.b-button.b-raised.b-green:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply bg-green-600;
}

.b-button.b-raised.b-purple {
    @apply bg-purple-600;
    @apply text-white;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-purple:focus,
.b-button.b-raised.b-purple.b-outer.b-using-keyboard:focus {
    @apply bg-purple-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-purple:focus:hover:not(.b-disabled),
.b-button.b-raised.b-purple.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-purple:hover:not(.b-disabled) {
    @apply bg-purple-300;
}

.b-button.b-raised.b-purple.b-pressed,
.b-button.b-raised.b-purple.b-pressed[data-group]:hover {
    @apply bg-purple-400;
}

.b-button.b-raised.b-purple.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-purple.b-pressed:focus:hover,
.b-button.b-raised.b-purple.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-purple.b-pressed:focus,
.b-button.b-raised.b-purple.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-purple-500;
}

.b-button.b-raised.b-purple.b-pressed:active {
    @apply bg-purple-700;
}

.b-button.b-raised.b-purple.b-pressed:active:hover {
    @apply bg-purple-500;
}

.b-button.b-raised.b-purple:active:not(.b-disabled) {
    @apply bg-purple-700;
    @apply border-purple-700;
}

.b-button.b-raised.b-purple:active:hover:not(.b-disabled) {
    @apply bg-purple-500;
    @apply border-purple-500;
}

.b-button.b-raised.b-purple:disabled:hover,
.b-button.b-raised.b-purple:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-purple-600;
}

.b-button.b-raised.b-yellow {
    @apply bg-yellow-600;
    @apply text-white;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-yellow:focus,
.b-button.b-raised.b-yellow.b-outer.b-using-keyboard:focus {
    @apply bg-yellow-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-yellow:focus:hover:not(.b-disabled),
.b-button.b-raised.b-yellow.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-yellow:hover:not(.b-disabled) {
    @apply bg-yellow-300;
}

.b-button.b-raised.b-yellow.b-pressed,
.b-button.b-raised.b-yellow.b-pressed[data-group]:hover {
    @apply bg-yellow-400;
}

.b-button.b-raised.b-yellow.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-yellow.b-pressed:focus:hover,
.b-button.b-raised.b-yellow.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-yellow.b-pressed:focus,
.b-button.b-raised.b-yellow.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-yellow-500;
}

.b-button.b-raised.b-yellow.b-pressed:active {
    @apply bg-yellow-700;
}

.b-button.b-raised.b-yellow.b-pressed:active:hover {
    @apply bg-yellow-500;
}

.b-button.b-raised.b-yellow:active:not(.b-disabled) {
    @apply bg-yellow-700;
    @apply border-yellow-700;
}

.b-button.b-raised.b-yellow:active:hover:not(.b-disabled) {
    @apply bg-yellow-500;
    @apply border-yellow-500;
}

.b-button.b-raised.b-yellow:disabled:hover,
.b-button.b-raised.b-yellow:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-yellow-600;
}

.b-button.b-raised.b-red {
    @apply bg-red-500;
    @apply text-white;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-red:focus,
.b-button.b-raised.b-red.b-outer.b-using-keyboard:focus {
    @apply bg-red-200;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-red:focus:hover:not(.b-disabled),
.b-button.b-raised.b-red.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-red:hover:not(.b-disabled) {
    @apply bg-red-300;
}

.b-button.b-raised.b-red.b-pressed,
.b-button.b-raised.b-red.b-pressed[data-group]:hover {
    @apply bg-red-400;
}

.b-button.b-raised.b-red.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-red.b-pressed:focus:hover,
.b-button.b-raised.b-red.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-red.b-pressed:focus,
.b-button.b-raised.b-red.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-red-500;
}

.b-button.b-raised.b-red.b-pressed:active {
    @apply bg-red-700;
}

.b-button.b-raised.b-red.b-pressed:active:hover {
    @apply bg-red-500;
}

.b-button.b-raised.b-red:active:not(.b-disabled) {
    @apply bg-red-700;
    @apply border-red-700;
}

.b-button.b-raised.b-red:active:hover:not(.b-disabled) {
    @apply bg-red-500;
    @apply border-red-500;
}

.b-button.b-raised.b-red:disabled:hover,
.b-button.b-raised.b-red:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-red-500;
}

.b-button.b-raised.b-dark-gray {
    @apply text-white;
    @apply bg-gray-600;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-dark-gray:focus,
.b-button.b-raised.b-dark-gray.b-outer.b-using-keyboard:focus {
    @apply bg-gray-200;
    @apply text-gray-900;
}

.b-outer.b-using-keyboard .b-button.b-raised.b-dark-gray:focus:hover:not(.b-disabled),
.b-button.b-raised.b-dark-gray.b-outer.b-using-keyboard:focus:hover:not(.b-disabled),
.b-button.b-raised.b-dark-gray:hover:not(.b-disabled) {
    @apply bg-gray-300;
    @apply text-gray-900;
}

.b-button.b-raised.b-dark-gray.b-pressed,
.b-button.b-raised.b-dark-gray.b-pressed[data-group]:hover {
    @apply bg-gray-400;
    @apply text-gray-900;
}

.b-button.b-raised.b-dark-gray.b-pressed:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-dark-gray.b-pressed:focus:hover,
.b-button.b-raised.b-dark-gray.b-outer.b-using-keyboard.b-pressed:focus:hover,
.b-outer.b-using-keyboard .b-button.b-raised.b-dark-gray.b-pressed:focus,
.b-button.b-raised.b-dark-gray.b-outer.b-using-keyboard.b-pressed:focus {
    @apply bg-gray-500;
}

.b-button.b-raised.b-dark-gray.b-pressed:active {
    @apply bg-gray-700;
}

.b-button.b-raised.b-dark-gray.b-pressed:active:hover {
    @apply bg-gray-500;
}

.b-button.b-raised.b-dark-gray:active:not(.b-disabled) {
    @apply bg-gray-700;
    @apply border-gray-700;
}

.b-button.b-raised.b-dark-gray:active:hover:not(.b-disabled) {
    @apply bg-gray-500;
    @apply border-gray-500;
}

.b-button.b-raised.b-dark-gray:disabled:hover,
.b-button.b-raised.b-dark-gray:disabled {
    @apply bg-gray-100;
    @apply text-gray-900;
    @apply border-gray-600;
}

/* Light gray button */

.b-button.b-light-gray:not(.b-tab) {
    @apply focus:ring-gray-400;
    @apply bg-gray-100;
    @apply border-gray-300;
    @apply text-gray-400;
}

.b-using-keyboard .b-button.b-light-gray:not(.b-tab):focus,
.b-butto.b-light-grayn:not(.b-tab).b-using-keyboard:focus {
    @apply bg-gray-400;
    @apply text-gray-700;
}

.b-button.b-light-gray:not(.b-tab).b-pressed {
    @apply bg-gray-400;
    @apply text-gray-700;
}

.b-button.b-light-gray:not(.b-tab):hover:not(.b-disabled) {
    @apply bg-gray-200;
    @apply text-gray-500;
}

.b-button.b-light-gray:not(.b-tab):hover:not(.b-disabled):not(.b-pressed) {
    @apply bg-gray-200;
    @apply text-gray-500;
}

.b-button.b-light-gray:not(.b-tab).b-pressed:hover {
    @apply bg-gray-200;
    @apply text-gray-500;
}

.b-button.b-light-gray:not(.b-tab):active:not(.b-disabled),
.b-button.b-light-gray:not(.b-tab):active:focus:not(.b-disabled) {
    @apply bg-gray-400;
    @apply text-gray-700;
}

.b-button.b-light-gray:not(.b-tab).b-disabled {
    @apply bg-gray-50;
    @apply border-gray-100;
    @apply text-gray-100;
}

.b-buttongroup .b-button {
    @apply border-b border-l-0 border-r-0 border-t;
}

.b-buttongroup .b-button.b-first-visible-child:not(.b-last-visible-child) {
    @apply border-l;
}

.b-buttongroup .b-button.b-last-visible-child:not(.b-first-visible-child) {
    @apply border-r;
}
