.b-grid-header-container {
    @apply border-gray-200;
}

.b-grid-header-container .b-sch-timeaxiscolumn {
    @apply border-gray-200 bg-gray-100 text-gray-900 !important;
}

.b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
    @apply border-gray-200;
}

.b-grid-header-container .b-sch-timeaxiscolumn.b-depth-0:hover,
.b-horizontaltimeaxis .b-sch-header-timeaxis-cell:hover,
.b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover,
.b-visible-scrollbar .b-show-yscroll-padding > .b-yscroll-pad {
    @apply bg-gray-100 !important;
}
