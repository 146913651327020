.b-popup-content.b-messagedialog-content.b-panel-content > div {
    @apply p-2;
}

.b-messagedialog.b-popup .b-messagedialog-header {
    @apply p-2 !important;
}

.b-messagedialog.b-popup .b-toolbar .b-toolbar-content {
    @apply justify-center !important;
}
