.b-color-blue {
    @apply bg-blue-500;
}

.b-color-orange {
    @apply bg-orange-600;
}

.b-color-amber {
    @apply bg-amber-600;
}

.b-color-green {
    @apply bg-green-600;
}

.b-color-yellow {
    @apply bg-yellow-600;
}

.b-color-red {
    @apply bg-red-500;
}

.b-color-dark-gray {
    @apply bg-gray-800;
}

.b-color-gray {
    @apply bg-gray-600;
}

.b-color-light-gray {
    @apply bg-gray-300;
}
