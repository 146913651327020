@import "topbar.css";
@import "event.css";
@import "sidebar.css";
@import "event-edit.css";

.b-calendar {
    @apply overflow-hidden !important;
}

.b-calendarrow .b-cal-cell-header.b-today .b-day-name-date {
    @apply bg-gray-100 text-gray-900;
}

.b-calendarrow .b-cal-cell-header.b-today:hover .b-day-name-date {
    @apply bg-gray-200;
}

.b-calendarrow .b-cal-cell-header.b-current-date .b-day-name-date {
    @apply bg-blue-600 text-white;
}

.b-calendarrow .b-cal-cell-header.b-current-date:hover .b-day-name-date {
    @apply bg-blue-700;
}

.b-calendarrow .b-cal-cell-header,
.b-calendarrow .b-calendar-cell {
    @apply text-gray-900;
}

.b-calendarrow .b-cal-cell-header.b-weekend {
    @apply text-red-600;
}
