@import "tooltip.css";
@import "widgets/index.css";
@import "widgets/message-dialog.css";

.b-grid .b-icon-collapse-left:before {
    content: "\f100";
}

.b-checkbox > .b-field-inner .b-checkbox-label:before {
    @apply h-4 w-4;
}
