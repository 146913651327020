.b-tabpanel-tab.b-active {
    @apply rounded-none border-0 border-b-2 border-blue-600;
    @apply bg-white text-gray-900;
    @apply hover:bg-white;
    @apply shadow-none;
    @apply focus:outline-none focus:ring-0 focus:ring-offset-0;
}

.b-tabpanel-body.b-card-container {
    @apply bg-white text-gray-900;
}

.b-panel.b-floating > .b-panel-body-wrap > .b-panel-content.b-eventeditor-content {
    @apply gap-y-4 pt-4;
}

.b-popup-content.b-panel-content.b-eventeditor-content > div.b-field {
    @apply px-4;
}

.b-eventeditor.b-popup {
    @apply w-[32rem] rounded-lg;
}

.b-panel.b-floating > .b-panel-header.b-dock-top,
.b-panel.b-floating:not(.b-has-header) > .b-panel-body-wrap > .b-toolbar.b-dock-top {
    @apply rounded-t-lg;
}

.b-panel .b-panel-body-wrap {
    @apply rounded-b-lg;
}
