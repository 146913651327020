.b-cal-event,
.b-cal-event-body,
.b-cal-event-body .b-cal-event-desc {
    @apply overflow-visible;
}

.b-cal-event-wrap.b-active:not(.b-dragging-item) {
    @apply z-[2] !important;
}

.b-cal-event-bar-container .b-cal-event {
    background-color: var(--cal-event-color);
}

.b-calendarpanel .b-calendar-cell {
    @apply text-left;
}

.b-cal-event-bar-container .b-cal-event-wrap:not(.b-solid-bar) .b-cal-event .b-icon {
    @apply hidden;
}
