.b-splitter.b-vertical {
    @apply min-w-[4px];
}

.b-splitter {
    flex: 0 0 4px;
}

.b-splitter.b-vertical:hover::after,
.b-splitter.b-vertical.b-moving::after {
    @apply w-[5px];
}
