.b-list-item.b-active:not(.b-disabled),
.b-list-item:focus:not(.b-disabled) {
    @apply bg-transparent;
}

.b-list-item.b-select-all-item.b-active:not(.b-disabled),
.b-select-all-item.b-list-item:focus:not(.b-disabled),
.b-list .b-select-all-item {
    @apply bg-white;
}

.b-calendar .b-sidebar-content .b-resourcefilter .b-icon:before {
    @apply flex;
}

.b-calendar .b-sidebar-content .b-list .b-select-all-item:not(.b-active),
.b-calendar .b-sidebar-content .b-list .b-list-title:not(.b-active) {
    @apply bg-white;
}
