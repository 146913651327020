@import "variables.css";
@import "rsuite/dist/rsuite.min.css";

.rs-calendar {
    min-height: 0;
}

.rs-picker-menu .rs-calendar-body {
    @apply pb-0;
}

.rs-calendar-time-dropdown {
    position: static;
}

button.rs-btn-primary {
    @apply relative;
    @apply inline-flex items-center;
    @apply px-2.5 py-1.5;
    @apply rounded border border-transparent;
    @apply text-sm font-bold text-white;
    @apply bg-blue-500 shadow-sm hover:bg-blue-700;
    @apply disabled:bg-blue-500;
    @apply disabled:opacity-50;
    @apply disabled:cursor-not-allowed !important;
    @apply focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
    @apply min-h-0;
    height: 2.3rem;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    @apply relative;
    @apply inline-flex items-center justify-center !important;
    @apply px-2.5 py-1.5;
    @apply rounded border border-transparent;
    @apply text-sm font-bold text-white;
    @apply bg-blue-500 shadow-sm hover:bg-blue-700;
    @apply disabled:bg-blue-500;
    @apply disabled:opacity-50;
    @apply disabled:cursor-not-allowed !important;
    @apply focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
    @apply min-h-0;
    height: 2.3rem;
}

.rs-picker-daterange-predefined {
    @apply px-2 !important;
}

.rs-picker-daterange-panel.rs-picker-daterange-panel-show-one-calendar > .rs-stack > .rs-stack-item {
    @apply flex;
    @apply flex-row-reverse;
}

.rs-picker-daterange-panel > .rs-stack > .rs-stack-item {
    @apply flex;
    @apply flex-col;
}

.rs-picker-toolbar {
    @apply flex;
    @apply flex-col;
    @apply border-r;
    @apply border-t-0;
    @apply pb-2;
}

.rs-picker-daterange-menu .rs-picker-toolbar {
    @apply mt-0;
}

.rs-picker-toolbar-ranges {
    @apply flex;
    @apply flex-col;
    @apply flex-grow;
}

.rs-picker-toolbar-ranges .rs-btn {
    @apply text-left;
}

.rs-picker-toggle-textbox,
.rs-picker-toggle-value {
    @apply text-gray-900 !important;
    @apply border-gray-300 focus:border-blue-500 focus:ring-blue-500 !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    @apply text-gray-900 !important;
    @apply border-gray-300 focus:border-blue-500 focus:ring-blue-500 !important;
}

.b-buttongroup .rs-picker-toggle {
    @apply !border-gray-300;
    @apply rounded-none !border-l-0 !border-r-0;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover:focus {
    @apply !border-gray-300;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
    @apply !border-gray-300 !shadow-none;
}

.icon-only.rs-picker-default .rs-picker-toggle.rs-btn {
    height: 36px;
    padding-right: 7px;
    padding-left: 7px;
    width: 32px;
    min-width: 32px;
}

.icon-only .rs-picker-toggle-textbox,
.icon-only .rs-picker-toggle-value {
    @apply hidden;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    @apply right-2 top-[6px] text-base text-gray-900 shadow-none;
}
