@import "header.css";
@import "group.css";
@import "row.css";

.b-grid-cell {
    @apply min-h-[43px] py-3;
}

.b-grid-header:not(.b-check-header) {
    @apply text-sm;
}

.b-grid-cell:not(.b-check-cell) {
    @apply text-sm;
}

.b-grid-cell.b-focused:after {
    border: none !important;
}

.b-grid-cell.b-checkbox-selection {
    background-color: inherit;
}

.b-checkbox:not(.b-disabled):hover .b-field-inner::before,
.b-checkbox:not(.b-disabled):focus .b-field-inner::before,
.b-checkbox:not(.b-disabled):hover:focus-within .b-field-inner::before,
.b-checkbox:not(.b-disabled):focus-within .b-field-inner::before {
    background: none;
}

.datatable .b-checkbox label {
    @apply mb-0;
}

.datatable .b-toolbar.b-dock-top {
    min-height: 3.5em;
}

.b-button.b-tool,
.b-tool {
    @apply text-gray-900;
}

.datatable .b-grid-header .b-checkbox {
    padding: 1.3em 0 1em 0;
}

.datatable .b-check-header-with-checkbox {
    justify-content: end;
}

.datatable .b-filter-bar-field {
    margin: 0 0 0.5em 0;
}

.b-widget.b-datetimefield.b-has-label,
.b-widget.b-datetimefield.b-match-label,
.b-widget.b-numberfield.b-has-label,
.b-widget.b-numberfield.b-match-label,
.b-widget.b-textfield.b-has-label,
.b-widget.b-textfield.b-match-label {
    @apply mt-0;
}

.b-pagingtoolbar {
    border-top: 1px solid;
    @apply border-gray-300;
}

.b-toolbar .b-toolbar-content {
    @apply py-2.5;
}
