.b-calendar-toolbar.b-top-toolbar .b-toolbar-content {
    @apply overflow-x-auto !important;
}

.b-toolbar.b-dock-top .b-toolbar-content > .b-widget:not(.b-button),
.b-toolbar.b-dock-bottom .b-toolbar-content > .b-widget:not(.b-button) {
    @apply min-h-[24px];
}

.b-calendar-body-wrap .b-toolbar-content {
    @apply items-center;
}
