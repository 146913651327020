.b-grid-cell.b-group-title:before {
    @apply absolute right-0;
}

.b-group-row {
    @apply bg-gray-100;
    @apply border-b border-gray-200 !important;
}

.b-group-row > :last-child:before {
    content: "\f0d7";
    font-family: "Font Awesome 6 Free", FontAwesome6Free;
    font-weight: 900;
    margin-left: auto;
}

.b-grid-group-collapsed.b-group-row > :last-child:before {
    content: "\f0da";
}

.b-group-state-icon {
    display: none;
}
