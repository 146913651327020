@import "variables.css";
@import "@bryntum/core-thin/core.stockholm.css";
@import "@bryntum/grid-thin/grid.stockholm.css";
@import "@bryntum/scheduler-thin/scheduler.stockholm.css";
@import "@bryntum/schedulerpro-thin/schedulerpro.stockholm.css";
@import "@bryntum/calendar-thin/calendar.stockholm.css";

@import "bryntum/buttons.css";
@import "bryntum/checkbox.css";
@import "bryntum/colors.css";
@import "bryntum/grid/index.css";
@import "bryntum/core/index.css";
@import "bryntum/scheduler-pro/index.css";
@import "bryntum/calendar/index.css";

.b-popup-header {
    @apply bg-gray-300;
    @apply text-gray-900;

    @apply pb-2 pl-4 pr-4 pt-2;
}

.b-messagedialog.b-popup .b-panel-body-wrap {
    @apply bg-gray-50;
    @apply text-gray-900;
}
.b-gridbase:not(.b-panel-ui-plain) .b-grid-cell.b-checkbox-selection .b-badge:before {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    right: -0.5rem;
    top: -0.5rem;

    @apply bg-red-500;
    @apply text-gray-50;
}

.b-mask-content {
    @apply bg-blue-500;
}

.b-panel-content,
.b-toolbar {
    @apply bg-white;
    @apply text-gray-900;
}

.b-calendar .b-print-content {
    font-family: "Inter var", sans-serif;
}

.b-cal-event-body {
    @apply w-full;
}

.b-cal-agenda-date {
    flex: 0 0 12em;
}

.b-cal-agenda-event-time {
    flex: 0 0 7em;
}

.b-cal-agenda-event-row .b-cal-event-wrap {
    flex: 0 0 15em;
}

.b-list-item.b-active:not(.b-disabled),
.b-list-item:focus:not(.b-disabled) {
    @apply text-gray-900;
}

.b-container .b-label,
.b-container .b-has-label.b-open .b-label,
.b-container .b-has-label:focus-within .b-label,
.b-container .b-has-label.b-ie:focus .b-label {
    @apply text-gray-900;
}

.b-numberfield input,
.b-numberfield textarea,
.b-textfield input,
.b-textfield textarea {
    @apply px-4 py-0;
}

.b-timepicker input {
    padding: 0;
    text-align: center;
}

.b-stripe .b-grid-row:last-child {
    border-bottom: 1px solid !important;
    @apply border-b !important;
    @apply border-gray-200 !important;
}

.b-field-inner {
    @apply border-gray-300;
}

.b-textfield .b-field-inner,
.b-textareafield .b-field-inner {
    @apply relative rounded-md shadow-sm;
    @apply min-h-0;
    height: 2.31rem;
}

.b-numberfield .b-field-inner {
    @apply min-h-0;
    height: 2.31rem;
}

.b-textfield .b-field-inner input,
.b-numberfield .b-field-inner input {
    @apply block;
    @apply text-gray-900 sm:text-sm;
    @apply rounded-md border-gray-300;
}

.b-textfield .b-field-inner input:focus,
.b-textareafield textarea:focus,
.select input:focus {
    box-shadow: initial;
}

.b-calendar > * > .b-top-toolbar {
    border-bottom: 1px solid;
    @apply border-gray-300;
}

.b-toolbar.b-dock-top .b-toolbar-content > .b-toolbar-separator,
.b-toolbar.b-dock-bottom .b-toolbar-content > .b-toolbar-separator {
    @apply border-gray-300;
    border-width: 0 1px 0 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.b-calendar .b-sidebar-content.b-panel-content {
    @apply border-r;
    @apply border-gray-300;
}

.b-widget,
.b-grid-header-text > .b-grid-header-text-content,
.b-calendar .b-calendar-view-desc {
    @apply text-base;
}

.b-grid-header-text > .b-grid-header-text-content {
    @apply font-semibold;
}

.b-datepicker .b-toolbar.b-dock-top,
.b-datepicker .b-calendar-weekdays {
    @apply bg-blue-500;
}

.b-toolbar.b-dock-top,
.b-toolbar.b-dock-bottom {
    min-height: 62px;
}

.b-datepicker .b-field div[type="text"] {
    @apply text-gray-900 sm:text-sm;
    @apply rounded border-gray-300;
}

.b-list-item {
    @apply text-gray-900;
    @apply text-base;
    @apply p-2;
}

.b-combo-picker .b-list-item:not(.b-disabled) {
    @apply bg-white;
    @apply text-gray-900;
}

.b-combo-picker .b-list-item.b-active:not(.b-disabled),
.b-combo-picker .b-list-item:focus:not(.b-disabled) {
    @apply bg-blue-500;
    @apply text-white;
}

.b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-selected-date {
    @apply bg-blue-500;
    @apply border-blue-500;
    @apply text-white;
}

.b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-today {
    @apply border-blue-500;
}

.b-datepicker
    .b-calendarpanel-content
    .b-calendar-row.b-calendar-week
    .b-calendar-cell:not(.b-disabled-date):not(.b-selected-date):hover.b-today {
    @apply border-blue-500;
    @apply bg-blue-500;
    @apply text-white;
}

.b-datepicker
    .b-calendarpanel-content
    .b-calendar-row.b-calendar-week
    .b-calendar-cell:not(.b-disabled-date):not(.b-selected-date):hover {
    @apply border-blue-500;
    @apply bg-blue-500;
    @apply text-white;
}

/* Dropdowns */

.b-popup.b-panel.b-floating {
    @apply rounded-lg
        bg-white
        shadow-lg
        ring-1
        ring-black
        ring-opacity-5
        focus:outline-none;
}

.b-menuitem {
    @apply bg-white text-gray-900 !important;
}

.b-popup-content.b-panel-content > div {
    @apply mb-0;
}

.b-popup.b-panel > .b-panel-body-wrap > .b-bottom-toolbar {
    @apply pt-4;
}

.b-widget.b-left-nav-btn {
    @apply mb-2 ml-2 !important;
}

.b-widget.b-right-nav-btn {
    @apply mb-2 mr-2;
}

.b-checkbox > .b-field-inner input[type="checkbox"]:checked + .b-checkbox-label:before,
.b-checkbox > .b-field-inner .b-checkbox-label:not(.b-radio-label):before {
    @apply m-0 mr-2 rounded;
}

.b-container .b-checkbox > .b-field-inner .b-checkbox-label {
    @apply text-base text-gray-900;
}

.b-checkbox > .b-field-inner input[type="checkbox"]:checked + .b-checkbox-label:before {
    @apply bg-blue-600 !important;
    @apply text-xs;
    --checkbox-border-color: #2563eb;
    --checkbox-background-color: #2563eb;
    --checkbox-check-color: #2563eb;
}

.b-gridbase.b-split .b-grid-splitter:not(.b-disabled) {
    flex: 0 0 4px;
}

.b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-inner,
.b-gridbase.b-split .b-grid-splitter:not(.b-disabled).b-hover .b-grid-splitter-inner,
.b-gridbase.b-split .b-grid-splitter:not(.b-disabled).b-touching .b-grid-splitter-inner,
.b-gridbase.b-split .b-grid-splitter:not(.b-disabled).b-moving .b-grid-splitter-inner {
    width: 4px;
}

.b-gridbase.b-split .b-grid-splitter:not(.b-disabled).b-hover .b-grid-splitter-buttons {
    @apply hidden;
}

/* Our own css */

.planning-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    height: 100%;
}

.open-advanced-filters {
    grid-template-rows: min-content min-content auto;
}

.b-mask-text {
    @apply text-white;
}

.b-textareafield.big-input .b-field-inner {
    height: 75px;
}

.hidden-checkbox-row .b-checkbox {
    @apply hidden;
}

.b-eventlist .b-grid-panel-body .b-cal-widget-settings-button {
    border-radius: 0.25rem;
}

.b-menuitem:hover {
    @apply bg-blue-500;
}

.b-eventeditor.b-popup .b-eventeditor-content.b-popup-content .b-textfield label,
.b-eventeditor.b-popup .b-eventeditor-content.b-popup-content .b-textareafield label,
.b-eventeditor.b-popup .b-eventeditor-content.b-popup-content .b-checkbox > label {
    flex: 1 0 6em;
}

.b-widget.b-fieldtrigger.b-icon-remove.b-align-end.b-icon {
    padding: 0 1rem 0 0;
}

.b-numberfield.b-empty .b-fieldtrigger.b-icon-remove,
.b-textfield.b-empty .b-fieldtrigger.b-icon-remove {
    display: none;
}

.b-widget {
    @apply overflow-visible;
}

.b-grid-header-scroller {
    @apply overflow-hidden;
}

.b-yearpicker .b-yearpicker-content button.b-yearpicker-year {
    color: inherit;
}

.b-grid-header-align-center {
    justify-content: center;
}

.b-label {
    text-transform: inherit !important;
}

.b-panel-header.b-dock-top {
    border-bottom: 0;
}

.b-panel.b-floating > .b-panel-body-wrap > .b-toolbar.b-dock-bottom {
    background-color: white;
}

.b-gridbase:not(.b-panel-ui-plain) .b-grid-cell.b-checkbox-selection {
    background-color: initial;
}

@media (min-width: 390px) {
    .b-eventeditor.b-popup .b-bottom-toolbar > .b-toolbar-content {
        padding-inline-start: var(--toolbar-content-padding);
    }
}

.b-gridbase.b-sort .b-grid-header .b-sort-icon:before {
    content: "";
    font-size: 0.8em;
    margin-inline-start: 0.5em;
    width: 1em;
}
